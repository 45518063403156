import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db, collection, query, where, getDocs, updateDoc, doc } from '../firebase';
import LinearProgress from '@mui/material/LinearProgress';

const Redirect = () => {
  const { id } = useParams();
  const [urlFound, setUrlFound] = useState(null);

  useEffect(() => {
    const fetchUrl = async () => {
      try {
        const q = query(collection(db, 'urls'), where('shortUrl', '==', id));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const urlData = querySnapshot.docs[0].data();
          setUrlFound(urlData.originalUrl);

          // Increment visits asynchronously
          const urlRef = doc(db, 'urls', querySnapshot.docs[0].id);
          updateDoc(urlRef, { visits: urlData.visits + 1 });
        } else {
          setUrlFound(false);
        }
      } catch (error) {
        console.error("Error fetching URL:", error);
        setUrlFound(false);
      }
    };

    fetchUrl();
  }, [id]);

  useEffect(() => {
    if (urlFound) {
      const timer = setTimeout(() => {
        window.location.href = urlFound;
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [urlFound]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', textAlign: 'center' }}>
      {urlFound === null ? (
        <>
          <p>Redirecting...</p>
          <LinearProgress style={{ width: '100%', maxWidth: '400px' }} />
        </>
      ) : urlFound ? (
        <>
          <p>Redirecting...</p>
          <LinearProgress style={{ width: '100%', maxWidth: '400px' }} />
        </>
      ) : (
        <p>Invalid URL. Please check the link and try again.</p>
      )}
    </div>
  );
};

export default Redirect;
